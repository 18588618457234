import React, { Fragment, useState, useEffect, Suspense } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon, XMarkIcon, UserGroupIcon, BookOpenIcon, ChartBarIcon, DocumentCheckIcon, ChartPieIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import ClipLoader from 'react-spinners/ClipLoader';
import logo from './../logoavanxa.svg';

// Lazy loading components
const Conteo = React.lazy(() => import('./contador'));
const Facilitadores = React.lazy(() => import('./facilitadores'));
const Tabla = React.lazy(() => import('./asignacion'));
const Biblia = React.lazy(() => import('./biblia'));
const EncuestaSatisfaccion = React.lazy(() => import('./encuestaSatisfacción'));
const EncuestaPerfilamiento = React.lazy(() => import('./encuestaPerfilamiento'));
const CertificadosUnab = React.lazy(() => import('./certificadosUnab'));
const GrupoEncuestas = React.lazy(() => import('./gruposEncuestas'));
const NpsChart = React.lazy(() => import('./panelInstituciones'))

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
    const [navigation, setNavigation] = useState([
  /*       { name: 'Facilitadores', href: '#', icon: UserGroupIcon, current: false, submenuOpen: false, submenu: [
            { name: 'Nuevo Facilitador', href: '#', icon: UserGroupIcon, submenuOpen: true },
            { name: 'Contador Certificados', href: '#', icon: HomeIcon }
        ] }, */
            { name: 'Encuesta Satisfacción', href: '#', icon: ChartBarIcon, submenuOpen: true},
            { name: 'Encuesta Perfilamiento (QA V1.1)', href: '#', icon: ChartPieIcon, submenuOpen: true},
            { name: 'Resultado Instituciones (QA V1.0)', href: '#', icon: ListBulletIcon },
/*             { name: 'Grupos', href: '#', icon: HomeIcon }
 */      
/*         { name: 'Certificados UNAB', href: '#', icon: DocumentCheckIcon, current: false },
        { name: 'Biblia (V2)', href: '#', icon: BookOpenIcon, current: false }, */
    ]);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeComponent, setActiveComponent] = useState('Encuesta Satisfacción');

    const toggleSubmenu = (index) => {
        const updatedNavigation = [...navigation];
        updatedNavigation[index].submenuOpen = !updatedNavigation[index].submenuOpen;
        setNavigation(updatedNavigation);
    };

    const changeActiveComponent = (component) => {
        setActiveComponent(component);

        const updatedNavigation = navigation.map((item) => ({
            ...item,
            current: item.name === component,
        }));
        setNavigation(updatedNavigation);
    };

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>
                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                            <img className="mx-auto h-10 w-auto rounded-full" src={logo} alt="Avanxa" />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col">
                                {navigation.map((item, index) => (
                                    <li key={item.name}>
                                        <div>
                                            <a
                                                href={item.href}
                                                onClick={() => {
                                                    changeActiveComponent(item.name);
                                                    if (item.submenu) toggleSubmenu(index);
                                                }}
                                                className={classNames(
                                                    item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                            {item.submenu && (
                                                <ul className={classNames(
                                                    item.submenuOpen ? 'block' : 'hidden',
                                                    'ml-4 space-y-1'
                                                )}>
                                                    {item.submenu.map((subitem) => (
                                                        <li key={subitem.name}>
                                                            <a
                                                                href={subitem.href}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    changeActiveComponent(subitem.name);
                                                                }}
                                                                className={classNames(
                                                                    subitem.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}
                                                            >
                                                                <subitem.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                                {subitem.name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="lg:pl-72">
                    <main className="py-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <Suspense fallback={<div className="flex justify-center items-center h-full"><ClipLoader color="#4A90E2" /></div>}>
                                {activeComponent === 'Contador Certificados' && (
                                    <>
                                        <Conteo />
                                        <Tabla navigation={navigation} />
                                    </>
                                )}
                                {activeComponent === 'Nuevo Facilitador' && <Facilitadores />}
                                {activeComponent === 'Biblia' && <Biblia />}
                                {activeComponent === 'Encuesta Satisfacción' && <EncuestaSatisfaccion />}
                                {activeComponent === 'Encuesta Perfilamiento (QA V1.1)' && <EncuestaPerfilamiento />}
                                {activeComponent === 'Certificados UNAB' && <CertificadosUnab />}
                                {activeComponent === 'Grupos' && <GrupoEncuestas />}
                                {activeComponent === 'Resultado Instituciones (QA V1.0)' && <NpsChart />}
                            </Suspense>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
