import React from 'react';
import './App.css';
import Dashboard from './components/dashboard.jsx';
import Login from './components/login.jsx';
import PrivateRoute from './components/privateRoute.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

 
function App() {
  return (
    <div className="App">
       <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={<PrivateRoute element={<Dashboard />} />}
        />
      </Routes>
      </Router>
    </div>
  );
}

export default App;
