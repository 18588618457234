import { useEffect, Fragment, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import logo from './../logoavanxa.svg'


export default function Login() {
    const [showModal, setShowModal] = useState(false);
    const [showModalSpinner, setShowModalSpinner] = useState(false);
    const [showModalError, setShowModalError] = useState(false);


    const navigate = useNavigate();
    useEffect(() => {
        const user = sessionStorage.getItem("user");
        if (user) {
            navigate("/");
        }
        // Agregar las clases al elemento html y body
        document.documentElement.classList.add('h-full');
        document.body.classList.add('h-full', 'bg-gray-900');

        // Limpiar las clases cuando el componente se desmonte
        return () => {
            document.documentElement.classList.remove('h-full');
            document.body.classList.remove('h-full', 'bg-gray-900');
        };
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault(); // Evita que se realice el envío del formulario por defecto

        const correo = event.target.email.value;
        const password = event.target.password.value;

        const dataCliente = {
            email: correo,
            password: password,
        };

        setShowModalSpinner(true)

        axios
            .post('https://backencuestas.avanxadev.com/v1/login', dataCliente)
            .then(function (response) {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.token);
                    setShowModalSpinner(false)
                    setShowModal(true);
                    sessionStorage.setItem("user", "authenticated"); // Establece la sesión del usuario
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
            })
            .catch(function (error) {
                setShowModalSpinner(false)
                setShowModalError(true);
                setTimeout(() => {
                    setShowModalError(false);
                }, 3000);
                console.log("Error en el envío", error);
            });
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
    className="mx-auto h-16 w-auto rounded-full"
    src={logo}
    alt="Avanxa"
  />

                </div>


                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                        <div >
                            <div className="flex items-start justify-between">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                    Correo electrónico
                                </label>
                            </div>

                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-start justify-between">
                                <label htmlFor="contraseña" className="block text-sm font-medium leading-6 text-white">
                                    Contraseña
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Iniciar Sesión
                            </button>
                        </div>
                    </form>


                </div>

            </div>
            {showModalSpinner && <ModalSpinner />} {/* Agrega esta línea para mostrar el modal */}
            {showModal && <Modal />} {/* Agrega esta línea para mostrar el modal */}
            {showModalError && <ModalError />} {/* Agrega esta línea para mostrar el modal */}

        </>
    )
}

function ModalSpinner() {
    const [open, setOpen] = useState(true);

    return (
        <Transition.Root show={open} as="div" className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="relative p-4 rounded-lg w-full max-w-sm">
                        <div className="flex justify-center">
                            <div className="animate-spin h-10 w-10 border-4 border-blue-500 rounded-full border-t-transparent"></div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Transition.Root>
    );
}





function Modal() {
    const [open, setOpen] = useState(true)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                                            Inicio de sesión exitoso
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function ModalError() {
    const [open, setOpen] = useState(true)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="bg-white relative transform overflow-hidden rounded-lg  p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 ">
                                            Usuario o Contraseña Incorrecta
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export { ModalSpinner }
